import { useEffect, useState } from 'react'
import axios from 'axios'

import RatingsPage from "./RatingsPage.js"
import { Typography, Container, TextField, Button, Stack, Select, MenuItem } from '@mui/material'

import Rating from './selection/Rating.js'
import NavBar from '../components/NavBar.js'
import Footer from '../components/Footer.js'

function SamplePage() {
    return (
        <div className='container'>
            <div className='left-panel black-bg'>
                <div class='scrollable-content'>
                    <RatingsPage />
                </div>
            </div>
            <div className='right-panel'>
                <div className='bkgd-panel'>
                    <div className="NominationPage">

                        <Container className='center'>
                            {/* User inputs album */}
                            <Typography variant='h5'>Nominate your wildcard album!</Typography>

                            <Stack spacing={5} justifyContent='center' alignItems='center'>
                                <div className='center'>
                                    <Typography>Album </Typography>
                                    <TextField id='nomination_album'></TextField><br></br>

                                    <Typography>Artist </Typography>
                                    <TextField id='nomination_artist'></TextField><br></br>
                                </div>

                                <Typography>OR</Typography>

                                <div className='center'>
                                    <Typography>Spotify Link </Typography>
                                    <TextField id='nomination_link'></TextField><br></br>
                                </div>
                            </Stack>

                            <Button variant='contained' className="button" id='search'>search</Button><br></br>

                        </Container>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SamplePage