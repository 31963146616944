import { Box, Stack, Typography } from '@mui/material'
import NavigationIcon from '@mui/icons-material/Navigation';
import { useState, useEffect } from 'react'
import axios from 'axios'

export default function Wheel({albums, initial, setInitial}) {
    const minAlbumsSpun = 50 // minimum number of albums to spin before landing (higher = longer spin)
    const albumWidth = 150

    const [displayAlbums, setDisplayAlbums] = useState(null) // list of albums concatted together to form long string that gets spun
    const [winner, setWinner] = useState(null)
    const [turnAmount, setTurnAmount] = useState(0)
    const [turnDuration, setTurnDuration] = useState(0)
    const [messages, setMessages] = useState("")

    useEffect(() => {
        getWinner() // finds the winning album
        // concat the top albums onto itself until reaches minimum albums to spin (scuffed)
        while(albums.length < minAlbumsSpun * 2) {
            albums = albums.concat(albums)
        }
        setDisplayAlbums(albums)
    }, [])

    useEffect(() => {
        if(winner && displayAlbums) {
            let index = displayAlbums.slice(minAlbumsSpun).findIndex((x) => x.ALBUM === winner.ALBUM) + minAlbumsSpun // index to land on winning album
            // calculate the number of pixels needed to move to land on index
            let randomSpin = Math.floor(Math.random() * 101) - 50 // random amount of spin between -50 and 50 pixels
            // let randomSpin = -150 // for testing purposes, -150 means the wheel will land on the album to the left
            let spinPixels = parseInt(index * albumWidth - window.innerWidth / 2 + albumWidth / 2 + randomSpin)
            setTimeout(() => { // wait 2 seconds
                setTurnAmount(spinPixels)
                setTurnDuration(11)
                handleResize()
                setTimeout(() => { // wait for spin to finish then show result
                    setInitial({...initial, confetti: true})
                    // setMessages("spin done")
                }, 12000)
            }, 2000)
        }
    }, [winner, displayAlbums])

    const handleResize = () => {
        if (winner && displayAlbums.length > 0) {
            let index = displayAlbums.slice(minAlbumsSpun).findIndex((x) => x.ALBUM === winner.ALBUM) + minAlbumsSpun;
            let randomSpin = Math.floor(Math.random() * 101) - 50;
            // let randomSpin = 100;
            let spinPixels = parseInt(index * albumWidth - window.innerWidth / 2 + albumWidth / 2 + randomSpin);
            setTurnAmount(spinPixels);
        }
    };
    
    useEffect(() => { // Recalculate the turnAmount when the window is resized
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [winner, displayAlbums, albumWidth, minAlbumsSpun]);

    return (
        <>
        {displayAlbums &&
        <>
            <Typography>
                {messages}
            </Typography>
            <Box position='relative' >
                <Box overflow='hidden'> 
                    <Stack direction='row' spacing={0} sx={{transform: `translate(-${turnAmount}px)`, transition: `${turnDuration}s ease-out` }}>
                        { displayAlbums.map((album) => (
                            <div>
                                <img className='no-img-style' src={album.IMAGE} width={albumWidth} height={albumWidth}></img>
                            </div>
                        ))}
                    </Stack>
                </Box>
                <Box textAlign='center'>
                    <NavigationIcon fontSize='large' sx={{color: '#843a3a'}}/>
                </Box>
            </Box>
        </>
        }
        </>
    )
    
    function getWinner() {
        let options = {
            method: 'GET',
            url: '/api/discussion-data'
        }
        axios.request(options).then((res) => {
            if(res.data.error) {
                setMessages({error: 'Error getting winning album.'})
                return
            }
            setWinner(res.data.data)
        })
    }
}