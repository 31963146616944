import React, { useState, useEffect } from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom"
import { Box, createTheme, ThemeProvider } from "@mui/material";

import AboutPage from "./pages/AboutPage"
import HomePage from "./pages/HomePage"
import SelectionPage from "./pages/SelectionPage"
import SamplePage from "./pages/SamplePage"
import RatingsPage from "./pages/RatingsPage"
import RegistrationPage from "./pages/RegistrationPage"
import NominationsPage from "./pages/NominationsPage"
import ProfilePage from "./pages/ProfilePage"
import NotFoundPage from "./pages/NotFoundPage"
import Rating from "./pages/selection/Rating"

import Footer from "./components/Footer"
import NavBar from "./components/NavBar"

import { UserContext } from "./contexts/UserContext"
import { MenuContext } from "./contexts/MenuContext"
import { MobileContext } from "./contexts/MobileContext";

// this is the base app off the website, everything loads off this page
function App() {

  // swipe to open menu logic
  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 100000

  const onTouchStart = (e) => {
    setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance

    if (isRightSwipe) {
      setOpenMenu(true)
    }
  }

  // useStates for the Contexts, the Contexts basically just let you access / change these values from other pages
  const [user, setUser] = useState({ registration_data: {} })
  const [openMenu, setOpenMenu] = useState(false)
  const [mobile, setMobile] = useState(window.innerWidth <= 900)

  useEffect(() => {
    // each time resized, check if mobile (less than 900 pixels width)
    const handleResize = () => { setMobile(window.innerWidth <= 900) }

    // Attach the event listener to the window object
    window.addEventListener('resize', handleResize);

    // Remove the event listener when the component unmounts (probably not necessary since its on the base App)
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  // mui theme for fonts and such, also sets stack default to sideways on desktop, vertical for mobile
  const theme = createTheme({
    palette: {
      primary: { main: '#843a3a' },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '30px',
            paddingLeft: '20px',
            paddingRight: '20px'
          }
        }
      }
    },
    typography: {
      button: {
        textTransform: 'none',
        fontSize: 'large'
      },
      h1: {
        fontSize: mobile ? '12vw' : '5.255vw',
        fontWeight: 'normal'
      },
      h2: {
        fontSize: mobile ? '11vw' : '4.204vw',
        fontWeight: 'normal'
      },
      h3: {
        fontSize: mobile ? '8.5vw' : '3.783vw',
        fontWeight: 'normal'
      },
      h4: {
        fontSize: mobile ? '7vw' : '2.522vw',
        fontWeight: 'normal'
      },
      h5: {
        fontSize: mobile ? '5.5vw' : '1.892vw',
        fontWeight: 'normal'
      },
      h6: {
        fontSize: mobile ? '4.75vw' : '1.576vw',
        fontWeight: 'normal'
      },
      p: {
        fontSize: mobile ? '3.8vw' : '1.261vw',
        fontWeight: 'normal'
      }
    }
  })


  return (
    <Box className="App tan-bg" onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
      <ThemeProvider theme={theme}>
        <UserContext.Provider value={{ user, setUser }}>
          <MenuContext.Provider value={{ openMenu, setOpenMenu }}>
            <MobileContext.Provider value={{ mobile, setMobile }}>
              <BrowserRouter>
                <NavBar />
                <Box className='pages' height='100%' minHeight={mobile ? 'calc(100vh - 55px - 55px)' : 'calc(100vh - 66px - 55px)'}>
                  <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path='/rate' element={<Rating />} />
                    <Route path='/about' element={<AboutPage />} />
                    <Route path='/selection' element={<SelectionPage />} />
                    <Route path='/register' element={<RegistrationPage />} />
                    <Route path='/ratings' element={<RatingsPage />} />
                    <Route path="/samplePage" element={<SamplePage />} />
                    <Route path='/nominations' element={<NominationsPage />} />
                    <Route path='/profile' element={<ProfilePage />} />
                    <Route path="/404" element={<NotFoundPage />} />
                    <Route path="*" element={<Navigate to="/404" />} />
                  </Routes>
                </Box>
                <Footer />
              </BrowserRouter>
            </MobileContext.Provider>
          </MenuContext.Provider>
        </UserContext.Provider>
      </ThemeProvider>
    </Box>
  );
}

export default App;