import { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import { Typography, Button, Divider, Container, Card, Checkbox, FormControlLabel, Stack, Box, Grid, TextField, CardMedia } from '@mui/material'
import { useMediaQuery } from '@mui/material';

import { UserContext } from '../contexts/UserContext'
import { MobileContext } from '../contexts/MobileContext'
import { Histogram } from '../components/Histogram'


function ProfilePage() {
    document.title = 'Profile | AOTW';

    const [messages, setMessages] = useState({})
    const { user } = useContext(UserContext)
    const { mobile } = useContext(MobileContext)


    const [ratings, setRatings] = useState([])
    const [ratingsLen, setRatingsLen] = useState([])


    const [topAlbums, setTopAlbums] = useState()
    const [bottomAlbums, setBottomAlbums] = useState()
    const [attendance, setAttendance] = useState()
    const [reviews, setReviews] = useState()
    const [average, setAverage] = useState()

    const [nickname, setNickname] = useState()

    const [name, setName] = useState()
    let finalName = name || "member";
    finalName = finalName.toLowerCase()

    const maxLength = 40
    let buttonText = "change name"
    buttonText = nickname && nickname.length > maxLength ? "nickname too long" : "change name";


    useEffect(() => {
        if (user.email) getProfile()
    }, [user])

    if (!user.email) {
        return (
            <Typography variant='h2'>Please sign in to access your profile</Typography>
        )
    }

    if (mobile) {
        return (
            <>
                <Grid className='img-bg' sx={{
                    display: "grid",
                    flexDirection: "column",
                    alignContent: "center",
                    alignSelf: "stretch",
                    width: "100vw",
                    minHeight: "89vh",
                }}>
                    <Stack direction="column" alignItems='center' display='flex' flexWrap='nowrap' spacing={2}>
                        <Grid justifyContent='center' container direction='column' alignItems='center' paddingTop='5%'>
                            {/*histogram*/}
                            <Card sx={{ alignItems: 'center', backgroundColor: '#fffef2', marginTop: '3%', padding: '4%', maxWidth: '85vw', borderRadius: '16px' }}>
                                <Stack Stack direction="column" alignItems='center' spacing={1}>
                                    {finalName &&
                                        <Typography
                                            variant='h1'
                                            sx={{
                                                fontWeight: 'medium',
                                                fontStyle: 'italic',
                                                color: 'black',
                                                textAlign: 'center',
                                                paddingBottom: '5%',
                                                paddingTop: '5%',
                                                overflowWrap: 'break-word',
                                                wordWrap: 'break-word',
                                                maxWidth: '100%'
                                            }}
                                        >
                                            {finalName}'s profile
                                        </Typography>
                                    }
                                    {ratingsLen && reviews && average && ratings &&
                                        <>
                                            <Grid sx={{ height: 'auto', flexDirection: "row", width: "100%", height: "100%", display: "flex", justifyContent: "space-between", justifySelf: "center", flexWrap: "nowrap", paddingBottom: '6%' }}>
                                                <Grid item sx={{ flexDirection: "column", height: "100%", alignItems: "center", display: "flex" }}>
                                                    <Typography sx={{ fontWeight: "bold", color: "#1A1C3A", fontSize: "15.763vw", lineHeight: "110%", textAlign: 'center' }}>{ratingsLen}</Typography>
                                                    <Typography sx={{ fontWeight: "bold", color: "#1A1C3A", textAlign: 'center' }} variant='h6'>albums</Typography>
                                                    <Typography sx={{ fontWeight: "bold", color: "#1A1C3A", textAlign: 'center' }} variant='h6'>rated</Typography>
                                                </Grid>
                                                <Divider sx={{ borderRightWidth: "3px", color: "#acacac" }} orientation="vertical"></Divider>
                                                <Grid item sx={{ flexDirection: "column", height: "100%", alignItems: "center", display: "flex" }}>
                                                    <Typography sx={{ fontWeight: "bold", color: "#1A1C3A", fontSize: "15.763vw", lineHeight: "110%", textAlign: 'center' }}>{reviews}</Typography>
                                                    <Typography sx={{ fontWeight: "bold", color: "#1A1C3A", textAlign: 'center' }} variant='h6'>albums</Typography>
                                                    <Typography sx={{ fontWeight: "bold", color: "##1A1C3A", textAlign: 'center' }} variant='h6'>reviewed</Typography>

                                                </Grid>
                                                <Divider sx={{ borderRightWidth: "3px", color: "#acacac", }} orientation="vertical"></Divider>
                                                <Grid item sx={{ flexDirection: "column", height: "100%", alignItems: "center", display: "flex" }}>
                                                    <Typography sx={{ fontWeight: "bold", color: "#1A1C3A", fontSize: "15.763vw", lineHeight: "110%", textAlign: 'center' }}>{average}</Typography>
                                                    <Typography sx={{ fontWeight: "bold", color: "#1A1C3A", textAlign: 'center' }} variant='h6'>average</Typography>
                                                    <Typography sx={{ fontWeight: "bold", color: "#1A1C3A", textAlign: 'center' }} variant='h6'>rating</Typography>

                                                </Grid>
                                            </Grid>
                                            <Grid container direction='column' height='15vh' alignItems='center' marginY='3%' paddingTop='10%'>
                                                <Box width='100%' height='100%'>
                                                    <Histogram data={ratings} />
                                                </Box>
                                            </Grid>
                                        </>
                                    }

                                </Stack>
                            </Card>

                            {/*change display name*/}
                            <Stack direction="column" justifyContent='center' alignItems='center' spacing={2}>
                                <Card sx={{ alignItems: 'center', maxWidth: '85vw', width: '100%', backgroundColor: '#fffef2', marginTop: '5%', marginBottom: '5%', padding: '5%', display: 'flex', borderRadius: '16px', flexDirection: 'column' }}>
                                    <Grid container direction='column' justifyContent='center' alignItems='center'>
                                        <Stack alignItems='end' spacing={1}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', whiteSpace: 'nowrap' }}>
                                                <Typography variant='h3' fontWeight='bold' fontStyle='italic' textAlign='end'>change display name</Typography>
                                                <Typography variant='body2' sx={{ fontSize: '3.5vw', fontWeight: 'regular', textAlign: 'end' }}>
                                                    please ensure your name is appropriate and identifying
                                                </Typography>
                                            </Box>
                                            <TextField sx={{ width: '100%' }} id='name-input' size="small" onChange={(e) => setNickname(e.target.value)} ></TextField>
                                            {buttonText &&
                                                <Button variant="contained" onClick={changeName} sx={{ borderRadius: '16px', marginTop: '1%' }}>{buttonText}</Button>
                                            }
                                        </Stack>
                                    </Grid>
                                </Card>
                            </Stack>

                            {/*top albums*/}
                            <Card sx={{ alignItems: 'center', minWidth: '85vw', maxWidth: '85vw', width: '100%', backgroundColor: '#fffef2', marginTop: '5%', padding: '3%', display: 'flex', borderRadius: '16px', flexDirection: 'column' }}>
                                <Typography textAlign='right' variant='h3' sx={{ fontWeight: 'bold', fontStyle: 'italic', paddingBottom: '2%' }}> your top rated albums</Typography>
                                {topAlbums &&
                                    <Grid container justifyContent='center' spacing={0.5} sx={{ display: 'flex' }}>
                                        {topAlbums.map(album => (
                                            album && (
                                                <Grid item key={album.id} xs={6}>
                                                    <Box className='albumImg' position='relative'>
                                                        <CardMedia component='img' image={album.IMAGE} alt={album.ARTIST + " - " + album.ALBUM + ": " + (album.RATING !== 0 ? album.RATING.toFixed(1) : 'No Rating')} sx={{ maxHeight: '33vw', maxWidth: '33vw' }} />
                                                        {album && (
                                                            <div className="badge" style={{ position: 'absolute', bottom: '-15%', right: '-6%' }}>
                                                                <div className="badge-content" style={{ fontSize: '1.3em', width: '7vw', height: '7vw' }}>
                                                                    {album.RATING !== 0 ? album.RATING.toFixed(1) : 'n/a'}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Box>
                                                </Grid>
                                            )
                                        ))}
                                    </Grid>
                                }
                            </Card>

                            {/*bottom albums*/}
                            <Card sx={{ alignItems: 'center', minWidth: '85vw', maxWidth: '85vw', width: '100%', backgroundColor: '#fffef2', marginTop: '10%', padding: '3%', display: 'flex', borderRadius: '16px', flexDirection: 'column' }}>
                                <Typography textAlign='right' variant='h3' sx={{ fontWeight: 'bold', fontStyle: 'italic', paddingBottom: '2%' }}> your lowest rated albums</Typography>
                                {topAlbums &&
                                    <Grid container justifyContent='center' spacing={0.5} sx={{ display: 'flex' }}>
                                        {bottomAlbums.map(album => (
                                            album && (
                                                <Grid item key={album.id} xs={6}>
                                                    <Box className='albumImg' position='relative'>
                                                        <CardMedia component='img' image={album.IMAGE} alt={album.ARTIST + " - " + album.ALBUM + ": " + (album.RATING !== 0 ? album.RATING.toFixed(1) : 'No Rating')} sx={{ maxHeight: '33vw', maxWidth: '33vw' }} />
                                                        {album && (
                                                            <div className="badge" style={{ position: 'absolute', bottom: '-15%', right: '-6%' }}>
                                                                <div className="badge-content" style={{ fontSize: '1.3em', width: '7vw', height: '7vw' }}>
                                                                    {album.RATING !== 0 ? album.RATING.toFixed(1) : 'n/a'}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Box>
                                                </Grid>
                                            )
                                        ))}
                                    </Grid>
                                }
                            </Card>

                            {/*review button*/}
                            <Stack direction="column" alignItems='center' display='flex' flexWrap='nowrap' spacing={2} sx={{ marginTop: '5%' }}>
                                <Card sx={{ alignItems: 'center', maxWidth: '85vw', width: '100%', backgroundColor: '#fffef2', marginTop: '10%', marginBottom: '10%', display: 'flex', borderRadius: '16px', flexDirection: 'column' }}>
                                    <Stack alignItems='center' justifyContent='center' spacing={0.5}>
                                        <Grid container alignItems='center' justifyContent='center' spacing={1} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                                            <Button variant='contained' href='https://tamuaotw.com/ratings' sx={{ marginTop: '10%', marginBottom: '7%' }}>
                                                write reviews
                                            </Button>
                                            <img src="/images/little-guys/review.png" alt="review-lilguy" width='30%' style={{ marginLeft: '2%', marginTop: '5%', marginBottom: '3%' }} />
                                        </Grid>
                                    </Stack>
                                </Card>
                            </Stack>
                        </Grid>
                    </Stack >
                </Grid >
            </>
        )
    }

    if (!mobile) {
        return (
            <>
                <Grid container spacing={0} minHeight='100vh'>
                    {/* Left side */}
                    <Grid item xs={6} className='page white-bg left-panel'>
                        {topAlbums && bottomAlbums &&
                            <Stack direction="column" spacing={0.5}>
                                {/*Top Albums*/}
                                <Box alignItems='center' sx={{ padding: '1%' }}>
                                    <Typography textAlign='right' variant='h4' sx={{ fontWeight: 'bold', fontStyle: 'italic', paddingBottom: '2%' }}> your top rated albums</Typography>
                                    <Grid container justifyContent='center' spacing={2} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                                        {topAlbums.map(album => (
                                            album && (
                                                <Grid item key={album.id} xs={3} sx={{ marginBottom: 2, marginRight: 6 }}>
                                                    <Box className='albumImg' display='inline-block' position='relative'>
                                                        <CardMedia component='img' image={album.IMAGE} alt={album.ARTIST + " - " + album.ALBUM + ": " + (album.RATING !== 0 ? album.RATING.toFixed(1) : 'No Rating')} sx={{ maxHeight: '12vw', maxWidth: '12vw' }} />
                                                        {album && (
                                                            <div className="badge" style={{ position: 'absolute', bottom: '2%', right: '2%' }}>
                                                                <div className="badge-content" style={{ fontSize: '1em', width: '3vw', height: '3vw' }}>
                                                                    {album.RATING !== 0 ? album.RATING.toFixed(1) : 'n/a'}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Box>
                                                </Grid>
                                            )
                                        ))}
                                    </Grid>
                                </Box>
                                {/* Bottom Albums */}
                                <Box>
                                    <Typography textAlign='left' variant='h4' sx={{ fontWeight: 'bold', fontStyle: 'italic', paddingBottom: '2%' }}> your lowest rated albums</Typography>
                                    <Grid container justifyContent='center' spacing={2} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                                        {bottomAlbums.map(album => (
                                            album && (
                                                <Grid item key={album.id} xs={3} sx={{ marginBottom: 2, marginRight: 6 }}>
                                                    <Box className='albumImg' display='inline-block' position='relative'>
                                                        <CardMedia component='img' image={album.IMAGE} alt={album.ARTIST + " - " + album.ALBUM + ": " + (album.RATING !== 0 ? album.RATING.toFixed(1) : 'No Rating')} sx={{ maxHeight: '12vw', maxWidth: '12vw' }} />
                                                        {album && (
                                                            <div className="badge" style={{ position: 'absolute', bottom: '2%', right: '2%' }}>
                                                                <div className="badge-content" style={{ fontSize: '1em', width: '3vw', height: '3vw' }}>
                                                                    {album.RATING !== 0 ? album.RATING.toFixed(1) : 'n/a'}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Box>
                                                </Grid>
                                            )
                                        ))}
                                    </Grid>
                                </Box>
                                <Stack justifyContent='center' marginTop='5%' spacing={2}>
                                    <Grid container justifyContent='center' spacing={2} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                                        <Button variant='contained' href='https://tamuaotw.com/ratings' sx={{ marginTop: '10%', marginBottom: '7%' }}>
                                            write reviews
                                        </Button>
                                        <img src="/images/little-guys/review.png" alt="review-lilguy" width='15%' style={{ marginLeft: '2%', marginTop: '5%', marginBottom: '3%' }} />
                                    </Grid>
                                </Stack>
                            </Stack>
                        }
                    </Grid>


                    {/* Right side */}
                    <Grid item xs={6} className='page img-bg right-panel' sx={{ paddingRight: '1%', justifyContent: 'flex-end' }}>
                        <Grid container direction='column' spacing={11} alignItems='flex-end' sx={{ width: '100%' }}>
                            {(finalName &&
                                <Typography
                                    variant='h2'
                                    sx={{
                                        fontWeight: 'bold',
                                        fontStyle: 'italic',
                                        color: 'white',
                                        textAlign: 'right',
                                        paddingBottom: '2%',
                                        paddingRight: '4%',
                                        paddingTop: `${finalName.length > 10 ? '12%' : '7%'}`,
                                        overflowWrap: 'break-word',
                                        wordWrap: 'break-word',
                                        maxWidth: '80%'
                                    }}
                                >
                                    {finalName}'s profile
                                </Typography>
                            )}
                            <Grid container direction='column' alignItems='center'>
                                <Card sx={{ height: '100%', width: '90%', paddingY: '5%', backgroundColor: 'white' }}>
                                    <Typography variant='h4' fontWeight='medium' textAlign='center'>change display name</Typography>
                                    <Typography variant='body1' sx={{ fontSize: '10pt', fontWeight: 'regular', textAlign: 'center' }}>please ensure your name is appropriate and identifying</Typography>
                                    <Grid container direction='column' alignItems='center' margin='2%'>
                                        <Stack alignItems='center' spacing={2}>
                                            <TextField id='name-input' size="small" onChange={(e) => setNickname(e.target.value)} ></TextField>
                                            {buttonText &&
                                                <Button variant="contained" onClick={changeName} sx={{ borderRadius: '20px', marginTop: '3%' }}>{buttonText}</Button>
                                            }
                                        </Stack>
                                    </Grid>
                                </Card>
                            </Grid>
                            {ratingsLen && reviews && average && ratings &&
                                <>
                                    <Grid container direction='column' alignItems='flex-end' sx={{ height: 'auto', minHeight: '80%', width: '100%', padding: '5%' }}>
                                        <Card sx={{ backgroundColor: 'white', padding: '5%', paddingY: '5%', borderRadius: '8px', width: '90%', minHeight: '80%' }}>
                                            <Grid sx={{ height: 'auto', flexDirection: "row", width: "100%", height: "100%", display: "flex", justifyContent: "space-between", justifySelf: "center", flexWrap: "nowrap" }}>
                                                <Grid item sx={{ flexDirection: "column", height: "100%", alignItems: "center", display: "flex" }}>
                                                    <Typography sx={{ fontWeight: "bold", color: "#1A1C3A", fontSize: "5.25vw", lineHeight: "110%", textAlign: 'center' }}>{ratingsLen}</Typography>
                                                    <Typography sx={{ fontWeight: "bold", color: "#1A1C3A", textAlign: 'center' }} variant='h6'>albums rated</Typography>
                                                </Grid>
                                                <Divider sx={{ borderRightWidth: "3px", color: "#acacac" }} orientation="vertical"></Divider>
                                                <Grid item sx={{ flexDirection: "column", height: "100%", alignItems: "center", display: "flex" }}>
                                                    <Typography sx={{ fontWeight: "bold", color: "##1A1C3A", fontSize: "5.25vw", lineHeight: "110%", textAlign: 'center' }}>{reviews}</Typography>
                                                    <Typography sx={{ fontWeight: "bold", color: "##1A1C3A", textAlign: 'center' }} variant='h6'>albums reviewed</Typography>
                                                </Grid>
                                                <Divider sx={{ borderRightWidth: "3px", color: "#acacac", }} orientation="vertical"></Divider>
                                                <Grid item sx={{ flexDirection: "column", height: "100%", alignItems: "center", display: "flex" }}>
                                                    <Typography sx={{ fontWeight: "bold", color: "##1A1C3A", fontSize: "5.25vw", lineHeight: "110%", textAlign: 'center' }}>{average}</Typography>
                                                    <Typography sx={{ fontWeight: "bold", color: "##1A1C3A", textAlign: 'center' }} variant='h6'>average rating</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction='column' height='100px' alignItems='center' marginY='3%'>
                                                <Box width='100%' height='100%'>
                                                    <Histogram data={ratings} />
                                                </Box>
                                            </Grid>
                                        </Card>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </Grid>
                </Grid >
            </>
        )
    }


    // gets profile data from backend
    function getProfile() {
        let options = {
            method: 'GET',
            url: '/api/get-profile',
            params: {
                email: user.email
            }
        }
        axios.request(options).then((res) => {
            if (res.data.error) {
                console.log("profile error")
                console.log(res.data.error)
            }
            setRatings(res.data.ratings)
            setTopAlbums(res.data.albums.filter((e) => e.LABEL == 'TOP'))
            setBottomAlbums(res.data.albums.filter((e) => e.LABEL == 'BOTTOM'))
            setAttendance(res.data.attendance.ATTENDANCE)
            setReviews(res.data.attendance.REVIEWS)
            setName(res.data.name)

            // doing funny business to calculate average cause i didnt wanna write a whole query for it when the data is there
            if (res.data.ratings) {
                let total = 0
                let count = 0
                for (let i = 0; i < res.data.ratings.length; i++) {
                    total += res.data.ratings[i].label * res.data.ratings[i].count
                    count += res.data.ratings[i].count
                }
                setAverage((total / count).toFixed(2))
                setRatingsLen(count)
            }
        }).catch((err) => {
            setMessages({ error: "There was an error getting your profile." })
        })
    }

    // send new name to backend
    function changeName() {

        if (nickname && nickname.length > maxLength) {
            setMessages({ error: "Nickname is too long" });
            document.getElementById('name-input').value = '';
            return;
        }

        let options = {
            method: 'POST',
            url: '/api/change-name',
            params: { email: user.email, name: nickname }
        }
        axios.request(options).then((res) => {
            if (res.data.error) {
                console.log(res.data.message)
                setMessages(res.data.message)
                return
            }
            setMessages({ success: "Successfully changed nickname" })
            setName(nickname)
            document.getElementById('name-input').value = '';
        }).catch((err) => {
            setMessages({ error: "There was an error setting your nickname" })
        })
    }
}

export default ProfilePage

/*
average rating
histogram of ratings
global average ratings
favorite album
least favorite album
meetings attended
reviews written

maybe have option to filter by genre

*/