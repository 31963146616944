import { Grid, Button, Stack, Box, Slider, Typography } from '@mui/material'
import { useContext, useRef, useState, useEffect } from 'react'
import { MobileContext } from '../contexts/MobileContext'

import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';


export default function RatingButtons({ rating, handle }) {

  const { mobile } = useContext(MobileContext)
  const colors = ['#b50101', '#ae2f00', '#a54500', '#985700', '#8b6600', '#7c7200', '#6c7d00', '#578800', '#3e9100', '#0a9918'];


  if (!mobile)
    return (
      <div className='center'>
        <Grid container columns={10} spacing={2} justifyContent="center">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
            <Grid item key={value} xs={3} sm={2} md={2} justifyContent='center' alignItems="center">
              <Button
                variant="contained"
                onClick={() => handle(value)}
                sx={{
                  backgroundColor: rating === value ? '#843a3a' : 'rgba(0, 0, 0, 0)',
                  color: rating === value ? 'white' : 'black',
                  border: rating === value ? 0 : 1,
                  height: '60px',
                  borderRadius: '100%',
                  '&:hover': {
                    backgroundColor: '#843a3a',
                    color: 'white',
                  },
                }}
              >
                {value}
              </Button>
            </Grid>
          ))}
        </Grid>
      </div>
    )


  if (mobile)
    return (
      <>
        <Typography textAlign='center' variant='h2' paddingTop='2%' color={colors[rating - 1]}>{rating > 0 && rating !== undefined ? rating : 'turn it up!'}</Typography>
        <Stack direction='row' paddingX='5%' width='90%' justifyContent='space-between' alignItems='center' marginTop='0px' paddingTop='0px'>
          <VolumeDownIcon />
          <Box width='70%' alignItems='center' display='flex'>
            <Slider defaultValue={0} width='80%' onChange={(_, val) => handle(Math.round(val / 10))} />
          </Box>
          <VolumeUpIcon />
        </Stack>
      </>
    )
}