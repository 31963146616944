import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { Button, Typography } from '@mui/material'
import { useContext } from 'react'
import { MobileContext } from '../contexts/MobileContext'

// simple button that turns into check when finished, 
//  action: what happens when clicked (function)
//  completed: boolean that says when finished
//  disabled: state that indicates loading and submit button should be disabled to deter spamming
export default function SubmitButton({ text, action, completed, disabled }) {
    const { mobile } = useContext(MobileContext)
    return (
        <>
            {completed &&
                <CheckCircleOutlineIcon style={{ fontSize: mobile ? '20vw' : '4vw', color: 'green' }}></CheckCircleOutlineIcon>
            }
            {!completed &&
                <Button variant='contained' id='submit' onClick={action} disabled={disabled} sx={{
                    margin: '10px'
                }}>
                    <Typography variant={mobile ? 'h3' : 'h5'} sx={{
                        color: "#fffef2"
                    }}>{text}</Typography>
                </Button>
            }
        </>
    )
}