import { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import { Drawer, Box, Typography, IconButton, Dialog, Button, Stack, TextField, Checkbox, FormControlLabel, Divider, Grid, CardMedia } from '@mui/material';
import { createTheme } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import CloseIcon from '@mui/icons-material/Close';

import { UserContext } from '../contexts/UserContext'
import { MobileContext } from '../contexts/MobileContext';
import { formatDate } from '../common';
import Loading from '../components/Loading'
import RatingButtons from '../components/RatingButtons'
import Rating from './selection/Rating'
import SubmitButton from '../components/SubmitButton';

function RatingsPage() {
  const [discussions, setDiscussions] = useState(null)
  const [selectedDiscussion, setSelectedDiscussion] = useState(null);
  const [rating, setRating] = useState(0)
  const [reviews, setReviews] = useState(null) // stores reviews of currently selected album
  const [editReview, setEditReview] = useState(false) // flag if the user is currently editing review
  const [writtenReview, setWrittenReview] = useState() // store current written review
  const [anonymous, setAnonymous] = useState(false) // store if review is anonymous
  const [uniqueLabels, setUniqueLabels] = useState() // stores the unique values of the labels used to cateogorize discussions (spring 2023, summer 2023, etc.)
  const [sortRatingValue, setSortRatingValue] = useState()
  const [message, setMessage] = useState({})
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(false)

  const { mobile } = useContext(MobileContext)
  const { user } = useContext(UserContext)

  document.title = "Ratings | AOTW"

  // get all discussions
  useEffect(() => {
    getRatings()
  }, [])


  // get reviews / rating when album selected or when user changes (so it can be checked if user is the owner)
  useEffect(() => {
    if (user.email) getUserRating()
    getReviews()
  }, [selectedDiscussion, user])


  const handleImageClick = (discussion) => {
    setSelectedDiscussion(discussion);
    setIsDrawerOpen(true);
  };


  const handleCloseDrawer = () => {
    setEditReview(false)
    setMessage({})
    setIsDrawerOpen(false);
  };

  const publishReview = () => {
    if (!user.email) {
      setMessage({ error: 'Please sign in before submitting review.' })
      return
    }

    if (writtenReview?.length > 1800) {
      setMessage({ error: 'Please shorten your review.' })
      return
    }

    setDisabledSubmit(true)

    let options = {
      method: 'POST',
      url: '/api/insert-review',
      data: { email: user.email, review: writtenReview, anonymous: anonymous, discussionID: selectedDiscussion.DISCUSSION_ID }
    }
    axios.request(options).then((res) => {
      if (res.data.error) {
        setMessage({ error: res.data.message })
        return
      }
      getReviews()
      setEditReview(false)
      setMessage({ success: 'Successfully published review!' })
    }).catch((err) => {
      setMessage({ error: 'Error submitting review' })
    }).finally(() => {
      setDisabledSubmit(false)
    })
  }


  const deleteReview = () => {
    if (!user.email) { // if not signed in, just clear boxes
      setWrittenReview('')
      setEditReview(false)
      return
    }
    let options = {
      method: 'POST',
      url: '/api/delete-review',
      data: { email: user.email, discussionID: selectedDiscussion.DISCUSSION_ID }
    }
    axios.request(options).then((res) => {
      if (res.data.error) {
        setMessage({ error: res.data.message })
        return
      }
      getReviews()
      setEditReview(false)
      setMessage({ success: 'Successfully deleted review!' })
    })
  }


  const getRatings = (event) => {
    let targetVal = 'Date'
    if (event && event.target && event.target.value) {
      targetVal = event.target.value
    }
    const url = (targetVal === 'Date') ? '/api/all-discussion-data' :
      (targetVal === 'Artist') ? '/api/all-discussion-data-alphabetical-artist' :
        (targetVal === 'Album') ? '/api/all-discussion-data-alphabetical-album' :
          (targetVal === 'Rating') ? '/api/all-discussion-data-rating-desc' :
            (targetVal === 'Genre') ? '/api/all-discussion-data-genre' :
              (targetVal === 'Year') ? '/api/all-discussion-data-release-year' :
                '/api/all-discussion-data';

    let options = {
      method: 'GET',
      url: url
    }
    axios.request(options).then((res) => {
      setDiscussions(res.data.discussions);
      setSelectedDiscussion(res.data.discussions[0])
      let uniqueValues = new Set()
      res.data.discussions.forEach(dict => {
        uniqueValues.add(dict.SEASON)
      })
      setUniqueLabels([...uniqueValues])
    })
  }


  function getUserRating() {
    if (!selectedDiscussion) return

    const options = {
      method: 'GET',
      url: '/api/user-ratings-specific',
      params: { email: user ? user.email : null, discussionID: selectedDiscussion.DISCUSSION_ID }
    }
    axios.request(options).then((res) => {
      if (res.data.error) {
        console.log(res.data.error)
        setMessage({ error: "Error recieving previous rating" })
        return
      }
      setRating(res.data.rating)
      if (res && res.data && typeof res.data.rating === 'undefined') setRating(-1)
    })
      .catch((err) => {
        console.log(err)
      })
  }


  function submitRating(rating) {
    if (!selectedDiscussion) return

    if (user === null || user.email === undefined) {
      console.log('sign in')
      setMessage({ error: 'Please sign in using your TAMU google account' })
      return
    }

    if (rating === undefined || rating === 0 || rating === -1) {
      console.log('no rating')
      return
    }

    setRating(rating)

    // send data to backend
    const options = {
      method: 'POST',
      url: '/api/insert-rating-specific',
      data: { rating: rating, email: user.email, discussionID: selectedDiscussion.DISCUSSION_ID }
    }
    axios.request(options).then((res) => {
      let err = res.data.error
      if (err) {
        console.log(err)
        setMessage({ error: res.data.message })
        return
      }
      setMessage({ success: res.data.message })
    })
      .catch((err) => {
        console.log(err)
        setMessage({ error: "Error connecting to database" })
      })
  }


  const getReviews = () => {
    if (!selectedDiscussion) return

    let options = {
      method: 'GET',
      url: '/api/album-reviews',
      params: { email: user ? user.email : null, discussionID: selectedDiscussion.DISCUSSION_ID }
    }
    axios.request(options).then((res) => {
      setReviews(res.data.reviews)
      // if the user has written a review for the album, set it as their current written review (so they can edit it)
      if (res.data.reviews.length > 0 && res.data.reviews[0].OWNER) {
        setWrittenReview(res.data.reviews[0].REVIEW)
        setAnonymous(res.data.reviews[0].ANONYMOUS)
      }
      else
        setWrittenReview('')
    })
  }


  function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }


  if (!discussions)
    return (<Loading />)


  return (
    <Box className={mobile ? 'img-bg' : 'tan-bg'}>
      <div class="ratings">
        {!mobile && <>
          <Typography variant='h3' paddingTop='2%'>past discussions
            <Tooltip title={
              <Typography variant='p'>
                This page is an archive of albums discussed in previous meetings, dating all the way back to Spring 2022.
                Click on any album to see more details and reviews! You may also leave your own rating and review,
                even for discussions which you might not have been able to attend!
              </Typography>
            }>
              <HelpIcon size="small" style={{ marginLeft: '5px', verticalAlign: 'super' }}>

              </HelpIcon>
            </Tooltip>
          </Typography>

          <Box margin='20px' marginLeft='25%' marginRight='25%'>
            {/* Selecting how albums are sorted */}
            <FormControl fullWidth>
              <InputLabel>Sort Ratings</InputLabel>
              <Select
                label={'Sort-Ratings'}
                value={sortRatingValue}
                onChange={getRatings}
              >
                <MenuItem value={'Date'}>Discussion Date</MenuItem>
                <MenuItem value={'Genre'}>AOTW Rotation Week</MenuItem>
                <MenuItem value={'Rating'}>AOTW Rating</MenuItem>
                <MenuItem value={'Year'}>Release Year</MenuItem>
                <MenuItem value={'Artist'}>Artist</MenuItem>
                <MenuItem value={'Album'}>Album</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </>} {mobile && <>
          <Box paddingY='3%' />
          <Box
            sx={{
              backgroundColor: '#843a3a',
              margin: '0 10px',
              borderRadius: '10px',
              padding: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
              marginBottom: '2%'
            }}
          >
            <Typography
              variant='h4'
              sx={{
                fontWeight: 'bold',
                fontStyle: 'italic',
                color: 'white',
                overflowWrap: 'break-word',
                wordWrap: 'break-word',
                maxWidth: '80%'
              }}
            >
              past discussions
              <Tooltip title={
                <Typography variant='p'>
                  This page is an archive of albums discussed in previous meetings, dating all the way back to Spring 2022.
                  Click on any album to see more details and reviews! You may also leave your own rating and review,
                  even for discussions which you might not have been able to attend!
                </Typography>
              }>
                <HelpIcon size="small" style={{ marginLeft: '5px', verticalAlign: 'super' }} />
              </Tooltip>
            </Typography>
            <Box sx={{ backgroundColor: '#fffef2', padding: '10px', display: 'flex', alignItems: 'center', width: '90%', margin: '0 auto', height: 'auto' }}>
              <Typography variant='h5' sx={{ marginRight: '10px' }}>
                Sort by:
              </Typography>
              <Box sx={{ width: '70%' }}>
                {/* Selecting how albums are sorted */}
                <FormControl fullWidth>
                  <InputLabel>Sort Ratings</InputLabel>
                  <Select
                    label={'Sort-Ratings'}
                    value={sortRatingValue}
                    onChange={getRatings}
                  >
                    <MenuItem value={'Date'}>Discussion Date</MenuItem>
                    <MenuItem value={'Genre'}>AOTW Rotation Week</MenuItem>
                    <MenuItem value={'Rating'}>AOTW Rating</MenuItem>
                    <MenuItem value={'Year'}>Release Year</MenuItem>
                    <MenuItem value={'Artist'}>Artist</MenuItem>
                    <MenuItem value={'Album'}>Album</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>
        </>}

        {/* Dialog for selected album */}
        <div key={'section.title'}>
          <Dialog
            sx={{
              '& .MuiDialogContent-root': {
                padding: 0, // Set padding to 0 or any value you prefer
              },
            }}
            fullWidth
            PaperProps={{ sx: { width: "1100", maxWidth: "1100px", height: "80%" } }}
            open={isDrawerOpen}
            onClose={handleCloseDrawer}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {mobile && <>
              <DialogTitle variant='h5' id="alert-dialog-title" align="center">
                {selectedDiscussion.ARTIST} - {selectedDiscussion.ALBUM}
              </DialogTitle>
            </>}

            <DialogContent sx={{ marginLeft: mobile ? '20px' : '0px', marginRight: mobile ? '20px' : '0px' }}>
              {!mobile && <>
                <Grid container height='100%' width='100%'>
                  <Box display="flex" justifyContent="center" style={{ width: '40%', backgroundColor: '#843a3a', overflow: 'hidden' }}>
                    {selectedDiscussion.IMAGE &&
                      <Box width='70%'>
                        <img src={selectedDiscussion.IMAGE} alt="Discussion" style={{ width: '100%', marginTop: '20px' }} />
                      </Box>
                    }
                  </Box>

                  <Grid item style={{ backgroundColor: '#fffef2', overflowY: 'auto', width: '60%' }}>
                    <Box padding={2} >
                      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                        <DialogContentText variant='h5' id="alert-dialog-title">
                          <Typography variant='h5' display='inline' sx={{ fontWeight: 'bold' }}>title: </Typography>{selectedDiscussion.ALBUM}
                        </DialogContentText>
                        <IconButton edge="end" color="inherit" onClick={handleCloseDrawer} aria-label="close">
                          <CloseIcon />
                        </IconButton>
                      </Box>
                      <DialogContentText variant='h5' id="alert-dialog-artist">
                        <Typography variant='h5' display='inline' sx={{ fontWeight: 'bold' }}>artist: </Typography>{selectedDiscussion.ARTIST}
                      </DialogContentText>
                      <DialogContentText variant='h5' id="alert-dialog-genre">
                        <Typography variant='h5' display='inline' sx={{ fontWeight: 'bold' }}>genre: </Typography>{selectedDiscussion.SUBGENRE}
                      </DialogContentText>
                      <br />
                      <DialogContentText variant='h5' id="alert-dialog-description">
                        <Typography variant='h5' display='inline' sx={{ fontWeight: 'bold' }}>rating: </Typography>{selectedDiscussion.RATING}
                      </DialogContentText>
                      {selectedDiscussion.FAVORITE_TRACK &&
                        <DialogContentText variant='h5' id="alert-dialog-description">
                          <Typography variant='h5' display='inline' sx={{ fontWeight: 'bold' }}>favorite track: </Typography> "{selectedDiscussion.FAVORITE_TRACK}"
                        </DialogContentText>
                      }
                      <DialogContentText variant='h5' id="alert-dialog-description">
                        <Typography variant='h5' display='inline' sx={{ fontWeight: 'bold' }}>discussed on: </Typography>{selectedDiscussion.DATE}
                      </DialogContentText>

                      {user.email ? (
                        <Box>
                          <Divider textAlign='center' sx={{ marginBottom: '1%', marginTop: '3%' }}>
                            <Typography variant='h6' sx={{ fontWeight: 'bold', fontStyle: 'italic', color: 'rgba(0, 0, 0, 0.6)' }} >your rating</Typography>
                          </Divider>
                          <RatingButtons rating={rating} handle={(e) => submitRating(e)}></RatingButtons>
                        </Box>
                      ) : null}

                      {reviews &&
                        <Box>
                          <Typography sx={{ color: 'red' }} align='center' variant='h2' id='display'>{message.error}</Typography>
                          <Typography sx={{ color: 'green' }} align='center' variant='h2' id='display'>{message.success}</Typography>

                          <Divider textAlign='center' sx={{ marginBottom: '1%', marginTop: '3%' }}>
                            <Typography variant='h6' sx={{ fontWeight: 'bold', fontStyle: 'italic', color: 'rgba(0, 0, 0, 0.6)' }} >member reviews</Typography>
                          </Divider>

                          {editReview &&
                            <Box marginTop='10px' marginBottom='5%'>
                              <TextField fullWidth multiline value={writtenReview} onChange={(e) => { setWrittenReview(e.target.value) }} sx={{ marginBottom: '10px' }}></TextField>
                              <Typography color={writtenReview?.length > 1500 ? 'red' : 'black'}>{`${writtenReview?.length || 0} of max 1800 characters`}</Typography>
                              <Stack position='relative' marginTop='5%' margin='3%'>
                                {!mobile &&
                                  <>
                                    <FormControlLabel control={<Checkbox checked={anonymous} onChange={() => setAnonymous(!anonymous)} />} label="Anonymous" />
                                    <Box position='relative' width='100%'>
                                      <Stack direction='row' spacing={2} position='absolute' right={0}>
                                        <Button variant='contained' onClick={() => publishReview()} disabled={disabledSubmit}>Publish</Button>
                                        <Button variant='contained' onClick={() => deleteReview()}>Delete</Button>
                                      </Stack>
                                    </Box>
                                  </>
                                }
                                {mobile &&
                                  <>
                                    <Box position='relative' width='100%'>
                                      <Stack direction='row' justifyContent='center' marginBottom='5%'>
                                        <FormControlLabel control={<Checkbox checked={anonymous} onChange={() => setAnonymous(!anonymous)} />} label="Anonymous" />
                                      </Stack>

                                      <Stack direction='row' spacing={2} justifyContent='center'>
                                        <Button variant='contained' onClick={() => publishReview()} disabled={disabledSubmit}>Publish</Button>
                                        <Button variant='contained' onClick={() => deleteReview()}>Delete</Button>
                                      </Stack>
                                    </Box>
                                  </>
                                }
                              </Stack>
                            </Box>
                          }
                          <Stack direction='column'>
                            {reviews.map((review) => (
                              <Box margin='15px'>
                                <Typography>{review.RATING}</Typography>
                                <Typography>{review.REVIEW}</Typography>
                                <Typography>{`- ${review.ANONYMOUS ? 'anonymous' : review.FIRST_NAME ?? 'deleted user'}`}</Typography>
                              </Box>
                            ))}
                          </Stack>
                        </Box>
                      }
                      {!reviews &&
                        <Loading />
                      }
                      <DialogActions>
                        <Stack direction='row' spacing={2} alignItems="center" flexWrap="wrap" marginLeft="10px" marginRight="10px">
                          {(reviews && (reviews.length === 0 || !reviews[0].OWNER)) && !editReview && // first review always belongs to the signed in user
                            <Button variant='outlined' onClick={() => setEditReview(true)}>Add review +</Button>
                          }
                          {(reviews && reviews.length > 0 && reviews[0].OWNER) && !editReview && // first review always belongs to the signed in user
                            <Button variant='outlined' onClick={() => setEditReview(true)}>Edit review</Button>
                          }
                          <Button variant='contained' onClick={handleCloseDrawer}>Close</Button>
                        </Stack>
                      </DialogActions>
                    </Box>
                  </Grid>
                </Grid>
              </>} {mobile && <Box width='100%'>
                <Box display="flex" justifyContent="center" style={{ overflow: 'hidden', marginBottom: '16px' }}>
                  {selectedDiscussion.IMAGE &&
                    <img src={selectedDiscussion.IMAGE} alt="Discussion" style={{ width: '240px', height: '240px' }} />
                  }
                </Box>
                <DialogContentText variant='h5' id="alert-dialog-genre">
                  <Typography variant='h5' display='inline' sx={{ fontWeight: 'bold' }}>genre: </Typography>{selectedDiscussion.SUBGENRE}
                </DialogContentText>
                <DialogContentText variant='h5' id="alert-dialog-description">
                  <Typography variant='h5' display='inline' sx={{ fontWeight: 'bold' }}>rating: </Typography>{selectedDiscussion.RATING}
                </DialogContentText>
                {selectedDiscussion.FAVORITE_TRACK &&
                  <DialogContentText variant='h5' id="alert-dialog-description">
                    <Typography variant='h5' display='inline' sx={{ fontWeight: 'bold' }}>favorite track: </Typography> "{selectedDiscussion.FAVORITE_TRACK}"
                  </DialogContentText>
                }
                <DialogContentText variant='h5' id="alert-dialog-description">
                  <Typography variant='h5' display='inline' sx={{ fontWeight: 'bold' }}>discussed on: </Typography>{selectedDiscussion.DATE}
                </DialogContentText>

                {user.email &&
                  <Stack direction='column' width='100%' justifyContent='center'>
                    <Divider textAlign='center' sx={{ marginBottom: '1%' }}>
                      <Typography variant='h6' sx={{ fontWeight: 'bold', fontStyle: 'italic' }} >your rating</Typography>
                    </Divider>
                    <RatingButtons rating={rating} handle={(e) => setRating(e)}></RatingButtons>
                    <Box display='flex' justifyContent='center'>
                      <SubmitButton text='update rating' completed={message.success} action={() => submitRating(rating)} />
                    </Box>
                  </Stack>
                }

                {reviews &&
                  <Box>
                    <Typography sx={{ color: 'red' }} align='center' variant='h2' id='display'>{message.error}</Typography>
                    <Typography sx={{ color: 'green' }} align='center' variant='h2' id='display'>{message.success}</Typography>

                    <Divider textAlign='center' sx={{ marginBottom: '1%' }}>
                      <Typography variant='h6' sx={{ fontWeight: 'bold', fontStyle: 'italic' }} >member reviews</Typography>
                    </Divider>

                    {editReview &&
                      <Box marginTop='10px' marginBottom='5%'>
                        <TextField fullWidth multiline value={writtenReview} onChange={(e) => { setWrittenReview(e.target.value) }} sx={{ marginBottom: '10px' }}></TextField>
                        <Typography color={writtenReview?.length > 1500 ? 'red' : 'black'}>{`${writtenReview?.length || 0} of max 1800 characters`}</Typography>
                        <Stack position='relative' marginTop='5%' margin='3%'>
                          {!mobile &&
                            <>
                              <FormControlLabel control={<Checkbox checked={anonymous} onChange={() => setAnonymous(!anonymous)} />} label="Anonymous" />
                              <Box position='relative' width='100%'>
                                <Stack direction='row' spacing={2} position='absolute' right={0}>
                                  <Button variant='contained' onClick={() => publishReview()} disabled={disabledSubmit}>Publish</Button>
                                  <Button variant='contained' onClick={() => deleteReview()}>Delete</Button>
                                </Stack>
                              </Box>
                            </>
                          }
                          {mobile &&
                            <>
                              <Box position='relative' width='100%'>
                                <Stack direction='row' justifyContent='center' marginBottom='5%'>
                                  <FormControlLabel control={<Checkbox checked={anonymous} onChange={() => setAnonymous(!anonymous)} />} label="Anonymous" />
                                </Stack>

                                <Stack direction='row' spacing={2} justifyContent='center'>
                                  <Button variant='contained' onClick={() => publishReview()} disabled={disabledSubmit}>Publish</Button>
                                  <Button variant='contained' onClick={() => deleteReview()}>Delete</Button>
                                </Stack>
                              </Box>
                            </>
                          }
                        </Stack>
                      </Box>
                    }
                    <Stack direction='column'>
                      {reviews.map((review) => (
                        <Box margin='15px'>
                          <Typography>{review.RATING}</Typography>
                          <Typography>{review.REVIEW}</Typography>
                          <Typography>{`- ${review.ANONYMOUS ? 'anonymous' : review.FIRST_NAME ?? 'deleted user'}`}</Typography>
                        </Box>
                      ))}
                    </Stack>
                  </Box>
                }
                {!reviews &&
                  <Loading />
                }
              </Box>}
            </DialogContent>
            {mobile && <>
              <DialogActions>
                <Stack direction='row' spacing={2}>
                  {(reviews && (reviews.length === 0 || !reviews[0].OWNER)) && !editReview && // first review always belongs to the signed in user
                    <Button variant='outlined' onClick={() => setEditReview(true)}>Add review +</Button>
                  }
                  {(reviews && reviews.length > 0 && reviews[0].OWNER) && !editReview && // first review always belongs to the signed in user
                    <Button variant='outlined' onClick={() => setEditReview(true)}>Edit review</Button>
                  }
                  <Button variant='contained' onClick={handleCloseDrawer}>Close</Button>
                </Stack>
              </DialogActions>
            </>}

          </Dialog>

          {/* Holds the previous albums */}
          {!mobile && <>
            {uniqueLabels.map((label) => (
              <>
                <Typography variant='h5'>{label}</Typography>
                <Grid container justifyContent='center'>
                  {discussions.filter(e => e.SEASON === label).map((discussion) => (
                    <Box className="albumImg" onClick={() => handleImageClick(discussion)} sx={{ margin: '1%' }}>
                      <CardMedia component='img' image={discussion.IMAGE} alt={discussion.ARTIST + " - " + discussion.ALBUM + ": " + (discussion.RATING !== 0 ? discussion.RATING.toFixed(1) : 'No Rating')} />
                      {discussion && (
                        <div className="badge">
                          <div className="badge-content">
                            {discussion.RATING !== 0 ? discussion.RATING.toFixed(1) : 'n/a'}
                          </div>
                        </div>
                      )}
                    </Box>
                  ))}
                </Grid>
              </>
            ))}
          </>}
          {mobile && <>
            {uniqueLabels.map((label) => (
              <>
                <Grid container justifyContent='center'>
                  {discussions.filter(e => e.SEASON === label).map((discussion) => (
                    <Box onClick={() => handleImageClick(discussion)} sx={{ margin: '1%', backgroundColor: '#fffef2', display: 'flex', alignItems: 'center', width: '100%', borderRadius: '10px' }}>
                      <Box className="albumImg" sx={{ position: 'relative' }}>
                        <CardMedia component='img' image={discussion.IMAGE} alt={discussion.ARTIST + " - " + discussion.ALBUM + ": " + (discussion.RATING !== 0 ? discussion.RATING.toFixed(1) : 'No Rating')} />
                        {discussion && (
                          <div className="badge">
                            <div className="badge-content">
                              {discussion.RATING !== 0 ? discussion.RATING.toFixed(1) : 'n/a'}
                            </div>
                          </div>
                        )}
                      </Box>
                      <Box sx={{ marginLeft: '10px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <Typography variant='body2' sx={{ fontStyle: 'italic' }} align='left'>
                          {formatDate(discussion.DATE)}
                        </Typography>
                        <Typography variant='h6' sx={{ fontStyle: 'italic', fontWeight: 'bold' }} align='left'>
                          {discussion.ALBUM}
                        </Typography>
                        <Typography variant='body1' align='left'>
                          {discussion.ARTIST}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Grid>
              </>
            ))}
          </>}
        </div>
      </div>
    </Box>
  );
}

export default RatingsPage